import format from 'date-fns/format'
import moment from "moment"
export const formatDate = (date, dateFormat = 'dd.MM.yyyy HH:mm:SS', divide = false) => {
    if (!date) return ''

    const formattedDate = format(new Date(date), dateFormat)

    if (divide) {
        const [datePart, timePart] = formattedDate.split(' ')
        return `<div class="pp-date__date">${datePart}</div><div class="pp-date__time">${timePart}</div>`
    }

    return formattedDate
}

export const toSecondsStart = (date, formatDate = 'YYYY-MM-DD HH:mm') => {
    if (!date) return ''
    return moment(date, formatDate).unix()
}

export const toSecondsEnd = (date, formatDate = 'YYYY-MM-DD HH:mm') => {
    if (!date) return ''
    if (date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0) {
        return moment(date, formatDate).endOf('day').unix() 
    } else return moment(date, formatDate).unix()
}
